import React from "react";

const EditIcon: React.FunctionComponent<React.SVGProps<any>> = props => (
  <svg width={18} height={18} {...props}>
    <g fill="currentColor">
      <path d="M16.594 11.294a.449.449 0 00-.448.45v3.995a1.347 1.347 0 01-1.343 1.35H2.239a1.348 1.348 0 01-1.343-1.35V4.013c0-.745.601-1.35 1.343-1.35h3.975a.449.449 0 00.448-.45c0-.249-.2-.45-.448-.45H2.24A2.247 2.247 0 000 4.013v11.726a2.247 2.247 0 002.239 2.25h12.564a2.247 2.247 0 002.238-2.25v-3.995c0-.249-.2-.45-.447-.45z" />
      <path d="M16.864.605a2.008 2.008 0 00-2.85 0L6.026 8.633a.45.45 0 00-.115.198l-1.05 3.812a.451.451 0 00.114.438.448.448 0 00.437.116l3.792-1.056a.447.447 0 00.197-.116l7.988-8.028a2.034 2.034 0 000-2.864l-.525-.528zm-9.862 8.32l6.538-6.57 2.108 2.119-6.538 6.57-2.108-2.118zm-.421.85l1.684 1.693-2.33.649.646-2.342zM16.756 3.36l-.475.478-2.108-2.12.475-.477a1.115 1.115 0 011.583 0l.525.528a1.13 1.13 0 010 1.591z" />
    </g>
  </svg>
);

export default EditIcon;
