import { gql } from "@apollo/client";

export default gql`
  fragment GovernmentPostFields on GovernmentPost {
    _id
    body
    audience
    upvotesCount
    commentsCount
    viewerDidBookmark
    viewerDidUpvote
    viewerDidComment
    viewerCanEdit
    viewerCanDelete
    viewerCanComment
    createdAt
    featuredAt
    viewerIsAuthor
    created: createdAt
    author {
      ... on GovernmentUser {
        _id
        slug
        email
        fullName
        firstName
        avatar
        jobTitle
        government {
          _id
          name
          logo
          slug
          city {
            _id
            country
            countryName
          }
        }
      }
      ... on VendorUser {
        _id
        slug
        email
        fullName
        firstName
        avatar
        jobTitle
        company {
          _id
          name
          logo
          slug
        }
      }
      ... on PendingUser {
        _id
        slug
        fullName
        firstName
        email
        avatar
        jobTitle
      }
    }
    attachments {
      mimeType
      filename
      sizeInBytes
      url
    }
    links {
      addedAt
      description
      title
      favicon
      siteName
      url
    }
    government {
      _id
      slug
      name
      logo
      city {
        _id
        country
        countryName
      }
    }
  }
`;
