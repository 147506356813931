import { gql } from "@apollo/client";

export default gql`
  fragment FollowingFields on FollowTarget {
    ... on PendingUser {
      _id
      slug
      fullName
      jobTitle
      avatar
    }
    ... on GovernmentUser {
      _id
      slug
      fullName
      jobTitle
      avatar
      isInnovator
    }
    ... on VendorUser {
      _id
      slug
      fullName
      jobTitle
      avatar
      isDisruptor
    }
    ... on Government {
      _id
      slug
      name
      logo
    }
  }
`;
