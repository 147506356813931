import React from "react";
import ThumbsUpIcon from "~/components/icons/ThumbsUpIcon";
import ColoredThumbsUpIcon from "~/components/icons/ColoredThumbsUpIcon";

interface IThumbsUpButton {
  upvoted: boolean;
  onClick: React.MouseEventHandler;
  disabled?: boolean;
}

const ThumbsUpButton: React.FunctionComponent<IThumbsUpButton> = ({ onClick, upvoted, disabled = false }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      css={{
        border: 0,
        background: "none",
        display: "inline-flex",
        padding: 0,
        outline: "none",
        cursor: disabled ? "normal" : "pointer",
        height: 18,
      }}
    >
      {upvoted ? <ColoredThumbsUpIcon /> : <ThumbsUpIcon />}
    </button>
  );
};

export default ThumbsUpButton;
