import { Mutation } from "@apollo/client/react/components";
import gql from "graphql-tag";
import React from "react";
import * as Types from "~/types/types";
import ProfileQuery from "~/lib/queries/ProfileQuery";
import { IGetProfileQuery, IGetProfileQueryVariables } from "~/lib/queries/__generated__/ProfileQuery.generated";
import { IToggleUpvoteMutation, IToggleUpvoteMutationVariables } from "~/lib/mutations/__generated__/ToggleUpvoteMutation.generated";

type Viewer =
  | ({ __typename: "GovernmentUser" } & Pick<Types.IGovernmentUser, "slug">)
  | ({ __typename: "VendorUser" } & Pick<Types.IVendorUser, "slug">)
  | ({ __typename: "PendingUser" } & Pick<Types.IPendingUser, "slug">);

interface IToggleUpvoteProps {
  viewer?: Viewer;
  viewerDidUpvote: boolean;
  upvotesCount: number;
  targetId: any;
  targetType: TargetType;
  entityType?: TargetType;
  children: (v: IToggleChildren) => any;
}

type TargetType = "PRODUCT" | "STORY" | "RESOURCE" | "COMMENT" | "REPORT" | "PROJECT" | "POST";

interface IToggleChildren {
  toggle: () => void;
}

const ToggleUpvote: React.FunctionComponent<IToggleUpvoteProps> = ({
  viewer,
  upvotesCount,
  viewerDidUpvote,
  targetId,
  targetType,
  entityType,
  children,
  ...props
}) => {
  return (
    <Mutation<IToggleUpvoteMutation, IToggleUpvoteMutationVariables>
      mutation={ToggleUpvoteMutation}
      variables={{
        targetId,
        targetType: targetType as any,
      }}
      {...props}
    >
      {(toggleUpvote) => {
        return children({
          toggle: () => {
            toggleUpvote({
              optimisticResponse: {
                __typename: "Mutation",
                toggleUpvote: {
                  __typename: guessTypename(targetType, entityType) as any,
                  _id: targetId,
                  viewerDidUpvote: !viewerDidUpvote,
                  upvotesCount: upvotesCount + (viewerDidUpvote ? -1 : 1),
                },
              },
              update: (proxy) => {
                if (!viewer) {
                  return;
                }
                try {
                  const data = proxy.readQuery<IGetProfileQuery, IGetProfileQueryVariables>({
                    query: ProfileQuery,
                    variables: {
                      slug: viewer.slug!,
                    },
                  });

                  if (!data) {
                    return;
                  }

                  const { upvotesCount } = data.user!;

                  proxy.writeQuery({
                    query: ProfileQuery,
                    variables: {
                      slug: viewer.slug,
                    },
                    data: {
                      user: {
                        ...data.user!,
                        upvotesCount: upvotesCount! + (viewerDidUpvote ? -1 : 1),
                      },
                    },
                  });
                } catch (e) {} // eslint-disable-line
              },
            });
          },
        });
      }}
    </Mutation>
  );
};

const ToggleUpvoteMutation = gql`
  mutation ToggleUpvote($targetId: ObjectId!, $targetType: UpvoteTargetType!) {
    toggleUpvote(targetId: $targetId, targetType: $targetType) {
      ... on Product {
        _id
        viewerDidUpvote
        upvotesCount
      }
      ... on Story {
        _id
        viewerDidUpvote
        upvotesCount
      }
      ... on Project {
        _id
        viewerDidUpvote
        upvotesCount
      }
      ... on Comment {
        _id
        viewerDidUpvote
        upvotesCount
      }
      ... on Post {
        ... on GovernmentPost {
          _id
          viewerDidUpvote
          upvotesCount
        }
        ... on GroupPost {
          _id
          viewerDidUpvote
          upvotesCount
        }
        ... on VendorPost {
          _id
          viewerDidUpvote
          upvotesCount
        }
      }
    }
  }
`;

function guessTypename(targetType: string, entityType?: TargetType): string {
  if (targetType === "STORY") {
    return "Story";
  } else if (targetType === "PRODUCT") {
    return "Product";
  } else if (targetType === "PROJECT") {
    return "Project";
  } else if (targetType === "POST") {
    return "Post";
  } else if (entityType) {
    return entityType;
  }

  throw new Error("Invalid target type");
}

export default ToggleUpvote;
