import { gql } from "@apollo/client";

export default gql`
  fragment GroupPostFields on GroupPost {
    _id
    body
    audience
    upvotesCount
    commentsCount
    viewerDidBookmark
    viewerDidUpvote
    viewerDidComment
    viewerCanEdit
    viewerCanDelete
    viewerCanComment
    createdAt
    featuredAt
    viewerIsAuthor
    created: createdAt
    author {
      ... on GovernmentUser {
        _id
        slug
        fullName
        firstName
        avatar
        email
        jobTitle
        government {
          _id
          name
          logo
          slug
        }
      }
      ... on VendorUser {
        _id
        slug
        fullName
        email
        firstName
        avatar
        jobTitle
        company {
          _id
          name
          logo
          slug
        }
      }
      ... on PendingUser {
        _id
        slug
        email
        fullName
        firstName
        avatar
        jobTitle
      }
    }
    attachments {
      mimeType
      filename
      sizeInBytes
      url
    }
    links {
      addedAt
      description
      title
      favicon
      siteName
      url
    }
    group {
      _id
      name
      slug
    }
  }
`;
