import { Mutation } from "@apollo/client/react/components";
import React, { ReactElement } from "react";
import ToggleBookmarkMutation from "~/lib/mutations/ToggleBookmarkMutation";
import {
  IToggleBookmarkMutation,
  IToggleBookmarkMutationVariables,
} from "~/lib/mutations/__generated__/ToggleBookmarkMutation.generated";

const getTypename = (typename: string): string => {
  if (typename === "STORY") {
    return "Story";
  }

  if (typename === "PRODUCT") {
    return "Product";
  }

  if (typename === "POST") {
    return "Post";
  }

  return "Project";
};

interface IToggleBookmarkChildProps {
  toggle: () => void;
}

interface IToggleBookmarkProps {
  targetId: string;
  targetType: "STORY" | "PRODUCT" | "PROJECT" | "POST";
  viewerDidBookmark: boolean;
  children: (props: IToggleBookmarkChildProps) => ReactElement | null;
}

export default function ToggleBookmark({
  targetId,
  targetType,
  viewerDidBookmark,
  children,
}: IToggleBookmarkProps): ReactElement {
  return (
    <Mutation<IToggleBookmarkMutation, IToggleBookmarkMutationVariables>
      mutation={ToggleBookmarkMutation}
      variables={{
        targetId,
        targetType: targetType as any,
      }}
    >
      {(toggleBookmark) =>
        children({
          toggle: () => {
            toggleBookmark({
              optimisticResponse: {
                __typename: "Mutation",
                toggleBookmark: {
                  __typename: getTypename(targetType) as any,
                  _id: targetId,
                  viewerDidBookmark: !viewerDidBookmark,
                },
              },
            });
          },
        })
      }
    </Mutation>
  );
}
