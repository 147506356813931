import React from "react";
import * as palette from "@govlaunch/palette";
import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import FieldValidationError from "~/components/form/FieldValidationError";
import { Margin } from "~/components/spacings";
import { useIntl } from "react-intl";
import { omit } from "lodash/fp";

export default function BodyField() {
  const intl = useIntl();

  return (
    <Field name="body">
      {({ input, meta }) => (
        <div>
          <TextareaAutosize
            {...omit(["onFocus"], input)}
            minRows={3}
            placeholder={intl.formatMessage({
              defaultMessage: 'Add a description for your post', id: '2aNxft',
            })}
            css={{
              width: "100%",
              padding: 10,
              fontSize: 14,
              border: `solid 1px ${palette.lightestGray}`,
              borderRadius: 4,
              outline: 0,
              resize: "none",
              "&:focus": {
                border: `solid 1px ${palette.primary}`,
              },
              "&::placeholder": {
                color: palette.sealBlue,
                userSelect: "none",
              },
            }}
          />

          <Margin mt={4}>
            <div
              css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <FieldValidationError meta={meta} />
              </div>

              <FieldCharacterCount
                maxLength={280}
                description={input.value}
                css={{
                  marginLeft: "auto",
                }}
              />
            </div>
          </Margin>
        </div>
      )}
    </Field>
  );
}

interface IFieldCharacterCountProps {
  description: string;
  maxLength: number;
  css: { [key: string]: any };
}

function FieldCharacterCount({ description, maxLength, css, ...props }: IFieldCharacterCountProps) {
  if (description.length > maxLength) {
    return (
      <span
        {...props}
        css={{
          ...css,
          color: palette.red,
          fontSize: 12,
          userSelect: "none",
        }}
      >
        {description.length}/{maxLength}
      </span>
    );
  }

  return (
    <span
      {...props}
      css={{
        ...css,
        color: palette.sealBlue,
        fontSize: 12,
        userSelect: "none",
      }}
    >
      {description.length}/{maxLength}
    </span>
  );
}
