import { Mutation } from "@apollo/client/react/components";
import React, { ReactElement, ReactNode, useState } from "react";
import { Modal, Button, Filestack } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import BodyField from "~/components/post/fields/BodyField";
import { Margin } from "~/components/spacings";
import { Form, Field } from "react-final-form";
import Popover from "@govlaunch/popover";
import { Hover } from "react-powerplug";
import LinksToolbarIcon from "~/components/post/icons/LinksToolbarIcon";
import ImageToolbarIcon from "~/components/post/icons/ImageToolbarIcon";
import DocumentToolbarIcon from "~/components/post/icons/DocumentToolbarIcon";
import LinkField from "~/components/post/fields/LinkField";
import * as Yup from "yup";
import { IGroup, IGovernment } from "~/types/types";
import PostToGovernment from "~/lib/mutations/PostToGovernment";
import PostToGroup from "~/lib/mutations/PostToGroup";
import EditPost from "~/lib/mutations/EditPost";
import {
  IPostToGovernmentMutation,
  IPostToGovernmentMutationVariables,
} from "~/lib/mutations/__generated__/PostToGovernment.generated";
import {
  IPostToGroupMutation,
  IPostToGroupMutationVariables,
} from "~/lib/mutations/__generated__/PostToGroup.generated";
import { IEditPostMutation, IEditPostMutationVariables } from "~/lib/mutations/__generated__/EditPost.generated";
import { convertValuesToVariables } from "~/components/post/utils";

import useIsMobile from "~/lib/hooks/useIsMobile";
import { IDeletePostMutation, IDeletePostMutationVariables } from "~/lib/mutations/__generated__/DeletePost.generated";
import DeletePost from "~/lib/mutations/DeletePost";
import { MobileScreen, MobileScreenHeader, MobileScreenBody } from "~/components/mobile";
import LinkCard from "~/components/post/LinkCard";
import RemoveButton from "~/components/post/RemoveButton";
import DocumentCard from "~/components/post/DocumentCard";
import validate from "~/lib/utils/validate";
import { FormattedMessage, useIntl } from "react-intl";
import useIntlValidationMessages from "~/lib/hooks/useIntlValidationMessages";
import { useRouter } from "next/router";

interface IToolbarItem {
  active?: boolean;
  tooltip: string;
  onClick?: () => any;
  children: ReactNode;
}

interface IPostFormModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  initialValues?: any;
  group?: Pick<IGroup, "_id" | "name">;
  government?: Pick<IGovernment, "_id" | "name">;
  onDelete?: () => void;
}

interface IPostFormScreen {
  isOpen: boolean;
  onRequestClose: () => void;
}

interface IPostFormSubtitle {
  initialValues?: any;
  group?: Pick<IGroup, "_id" | "name">;
  government?: Pick<IGovernment, "_id" | "name">;
}

const POST_INITIAL_STATE = {
  links: [],
  documents: [],
  images: [],
  audience: "public",
};

const noop = () => {};

export default function PostFormModal({
  isOpen,
  onRequestClose,
  initialValues = POST_INITIAL_STATE,
  group,
  government,
  onDelete = noop,
}: IPostFormModalProps) {
  const router = useRouter();
  if (!initialValues._id && ((!government && !group) || (government && group))) {
    throw new Error("Please specify either `government` or `group`");
  }

  return (
    <PostFormScreen isOpen={isOpen} onRequestClose={onRequestClose}>
      <Mutation<IPostToGovernmentMutation, IPostToGovernmentMutationVariables> mutation={PostToGovernment}>
        {(postToGovernment) => (
          <Mutation<IPostToGroupMutation, IPostToGroupMutationVariables> mutation={PostToGroup}>
            {(postToGroup) => (
              <Mutation<IEditPostMutation, IEditPostMutationVariables> mutation={EditPost}>
                {(editPost) => (
                  <>
                    <div>
                      <p
                        css={{
                          fontWeight: 500,
                          fontSize: 17,
                          color: palette.darkGray,
                          margin: "0 0 4px 0",
                        }}
                      >
                        <FormattedMessage defaultMessage="What do you want to talk about?" id="FK8v6/" />
                      </p>
                      <PostFormSubtitle government={government} group={group} initialValues={initialValues} />
                    </div>

                    <PostForm
                      onDelete={onDelete}
                      onRequestClose={onRequestClose}
                      initialValues={initialValues}
                      government={government}
                      onSubmit={(values) => {
                        // if there's id, post is being edited
                        if (initialValues._id) {
                          return editPost({
                            variables: {
                              id: initialValues._id,
                              post: {
                                ...convertValuesToVariables(values),
                                audience: values.group ? "community" : values.audience.toLowerCase(),
                              },
                            },
                          }).then(() => onRequestClose());
                        }

                        if (government) {
                          return postToGovernment({
                            variables: {
                              government: government._id,
                              post: convertValuesToVariables(values),
                            },
                          }).then(({ data }: any) => {
                            router.push("/posts/[postId]", `/posts/${data.postToGovernment._id}`);
                          });
                        } else if (group) {
                          return postToGroup({
                            variables: {
                              group: group._id,
                              post: {
                                ...convertValuesToVariables(values),
                                audience: "community",
                              },
                            },
                          }).then(({ data }: any) => {
                            router.push("/posts/[postId]", `/posts/${data.postToGroup._id}`);
                          });
                        }
                      }}
                    />
                  </>
                )}
              </Mutation>
            )}
          </Mutation>
        )}
      </Mutation>
    </PostFormScreen>
  );
}

interface IPostFormProps {
  onSubmit: (args?: any) => void;
  initialValues: any;
  onRequestClose: () => void;
  government?: Pick<IGovernment, "_id" | "name">;
  onDelete: () => void;
}

function PostForm({ onSubmit, initialValues, onRequestClose, onDelete }: IPostFormProps): ReactElement {
  const intl = useIntl();
  const intlValidationMessages = useIntlValidationMessages();
  const [isAddingLink, setIsAddingLink] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    body: Yup.string()
      .required(intlValidationMessages.cantBeBlank)
      .min(3, intlValidationMessages.minCharacters(3))
      .max(280, intlValidationMessages.maxCharacters(280)),
  });

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate(validationSchema)}>
      {({ handleSubmit, submitting, values, form }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Margin mt={16}>
              <BodyField />
            </Margin>

            {values.images.map((image: any) => (
              <div
                key={image.url}
                css={{
                  marginTop: 12,
                  position: "relative",
                }}
              >
                <img
                  src={image.url}
                  css={{
                    maxHeight: 250,
                    maxWidth: "100%",
                    objectFit: "contain",
                    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.39)",
                  }}
                />

                <RemoveButton
                  onClick={() => form.change("images", [])}
                  css={{
                    position: "absolute",
                    top: 10,
                    left: 10,
                  }}
                />
              </div>
            ))}

            {values.documents.map((document: any) => {
              const { filename, mimeType, sizeInBytes, url } = document;

              return (
                <Margin mt={12} key={url}>
                  <DocumentCard
                    url={url}
                    filename={filename}
                    mimeType={mimeType}
                    sizeInBytes={sizeInBytes}
                    onRemove={() => form.change("documents", [])}
                  />
                </Margin>
              );
            })}

            {isAddingLink && (
              <Margin mt={12}>
                <LinkField onCancel={() => setIsAddingLink(false)} />
              </Margin>
            )}

            {values.links && Array.isArray(values.links) && values.links.length > 0 && (
              <div
                css={{
                  marginTop: 15,
                }}
              >
                {values.links.map(({ url, ...meta }: any) => {
                  if (!meta) {
                    return null;
                  }

                  return (
                    <div key={url || ""}>
                      <LinkCard
                        url={url || ""}
                        title={meta.title || ""}
                        siteName={meta.siteName || ""}
                        description={meta.description || ""}
                        favicon={meta.favicon || ""}
                        onRemove={() => {
                          form.change("links", []);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            )}

            <Margin mt={16}>
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {values.links.length === 0 &&
                    values.images.length === 0 &&
                    values.documents.length === 0 &&
                    !isAddingLink && (
                      <div
                        css={{
                          display: "grid",
                          gridTemplateColumns: "repeat(3, 1fr)",
                          gridColumnGap: 12,
                          maxWidth: 121,
                        }}
                      >
                        <ToolbarItem
                          tooltip={intl.formatMessage({
                            defaultMessage: "Link",
                            id: "JBWS0c",
                          })}
                          onClick={() => setIsAddingLink(true)}
                        >
                          <LinksToolbarIcon />
                        </ToolbarItem>

                        <Field name="images">
                          {({ input }) => (
                            <Filestack
                              options={{
                                accept: ["image/jpeg", "image/jpg", "image/png", "image/gif"],
                              }}
                              onSuccess={({ filesUploaded }: any) => {
                                if (filesUploaded.length > 0) {
                                  const file = filesUploaded[0];

                                  input.onChange([
                                    {
                                      filename: file.filename,
                                      mimeType: file.mimetype,
                                      sizeInBytes: file.size,
                                      url: file.url,
                                    },
                                  ]);

                                  form.change("documents", []);
                                  form.change("links", []);
                                }
                              }}
                              customRender={({ onPick }: any) => (
                                <ToolbarItem
                                  tooltip={intl.formatMessage({
                                    defaultMessage: "Image",
                                    id: "+0zv6g",
                                  })}
                                  onClick={onPick}
                                >
                                  <ImageToolbarIcon />
                                </ToolbarItem>
                              )}
                            />
                          )}
                        </Field>

                        <Field name="documents">
                          {({ input }) => (
                            <Filestack
                              options={{
                                accept: [
                                  "application/msword",
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                  "application/vnd.ms-excel",
                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                  "application/pdf",
                                ],
                              }}
                              onSuccess={({ filesUploaded }: any) => {
                                if (filesUploaded.length > 0) {
                                  const file = filesUploaded[0];

                                  input.onChange([
                                    {
                                      filename: file.filename,
                                      mimeType: file.mimetype,
                                      sizeInBytes: file.size,
                                      url: file.url,
                                    },
                                  ]);

                                  form.change("links", []);
                                  form.change("images", []);
                                }
                              }}
                              customRender={({ onPick }: any) => (
                                <ToolbarItem
                                  tooltip={intl.formatMessage({
                                    defaultMessage: "Document",
                                    id: "wmirkP",
                                  })}
                                  onClick={onPick}
                                >
                                  <DocumentToolbarIcon />
                                </ToolbarItem>
                              )}
                            />
                          )}
                        </Field>
                      </div>
                    )}
                </div>

                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="xsmall"
                    color={submitting ? "disabled" : palette.innovatorBlue}
                    disabled={submitting}
                    type="submit"
                    css={{
                      userSelect: "none",
                    }}
                  >
                    {submitting ? (
                      <FormattedMessage defaultMessage="Saving" id="QLkzoS" />
                    ) : initialValues._id ? (
                      <FormattedMessage defaultMessage="Edit" id="wEQDC6" />
                    ) : (
                      <FormattedMessage defaultMessage="Save" id="jvo0vs" />
                    )}
                  </Button>
                </div>
              </div>
            </Margin>

            {initialValues._id && (
              <div
                css={{
                  borderTop: `1px solid ${palette.lightestGray}`,
                  paddingTop: 16,
                  marginTop: 16,
                }}
              >
                <Mutation<IDeletePostMutation, IDeletePostMutationVariables>
                  variables={{
                    id: initialValues._id,
                  }}
                  mutation={DeletePost}
                >
                  {(deletePost, { loading }) => (
                    <span
                      onClick={() => {
                        if (
                          window.confirm(
                            intl.formatMessage({
                              defaultMessage: "Do you want to delete this post?",
                              id: "ayJvhs",
                            }),
                          )
                        ) {
                          deletePost().then(() => {
                            onRequestClose();
                            onDelete();
                          });
                        }
                      }}
                      role="button"
                      aria-label={intl.formatMessage({
                        defaultMessage: "Delete this post",
                        id: "/Y4Y9C",
                      })}
                      css={{
                        color: palette.red,
                        fontSize: 14,
                        fontWeight: 500,
                        cursor: "pointer",
                        pointerEvents: loading ? "none" : "all",
                        "&:hover": {
                          opacity: 0.8,
                        },
                      }}
                    >
                      {loading ? (
                        <FormattedMessage defaultMessage="Please wait..." id="172N3w" />
                      ) : (
                        <FormattedMessage defaultMessage="Delete this post" id="/Y4Y9C" />
                      )}
                    </span>
                  )}
                </Mutation>
              </div>
            )}
          </form>
        );
      }}
    </Form>
  );
}

const ToolbarItem = ({ active = false, tooltip, ...props }: IToolbarItem): ReactElement => {
  return (
    <Hover>
      {({ hovered, bind }) => (
        <Popover
          isOpen={hovered}
          placement="top"
          backgroundColor={palette.innovatorBlue}
          render={() => {
            return (
              <div
                css={{
                  padding: 8,
                  color: palette.white,
                  fontSize: 12,
                  fontWeight: "bold",
                }}
              >
                {tooltip}
              </div>
            );
          }}
          borderColor="transparent"
          boxStyle={{
            boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.2)",
            zIndex: 9999,
          }}
        >
          {({ innerRef }: any) => {
            return (
              <div
                {...props}
                {...bind}
                ref={innerRef}
                css={{
                  height: 35,
                  width: 35,
                  borderRadius: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: active ? "#b9e2fd" : palette.lightestBlue,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: active ? "#b9e2fd" : palette.lightBlue,
                  },
                }}
              />
            );
          }}
        </Popover>
      )}
    </Hover>
  );
};

const PostFormScreen: React.FunctionComponent<IPostFormScreen> = ({ children, isOpen, onRequestClose }) => {
  const isMobile = useIsMobile();

  if (isMobile && isOpen) {
    return (
      <MobileScreen isOpen={isOpen}>
        {() => (
          <>
            <MobileScreenHeader onRequestClose={onRequestClose}>
              <FormattedMessage defaultMessage="New Post" id="BpOiBa" />
            </MobileScreenHeader>

            <MobileScreenBody>{children}</MobileScreenBody>
          </>
        )}
      </MobileScreen>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overflow: "visible",
      }}
      onRequestClose={() => onRequestClose()}
      appElement={process.browser ? document.body : null}
    >
      <span
        role="button"
        css={{
          color: palette.darkGray,
          fontSize: 24,
          fontWeight: 500,
          position: "absolute",
          top: 12,
          right: 12,
          width: 12,
          height: 12,
          cursor: "pointer",
        }}
        onClick={() => onRequestClose()}
      >
        &times;
      </span>
      <div
        css={{
          width: isMobile ? 350 : 555,
          padding: 16,
        }}
      >
        {children}
      </div>
    </Modal>
  );
};

const PostFormSubtitle: React.FunctionComponent<IPostFormSubtitle> = ({ government, group, initialValues }) => {
  const intl = useIntl();

  const paragraphCSS = {
    margin: 0,
    fontSize: 12,
    color: palette.darkGray,
  };

  if (government) {
    return (
      <p css={paragraphCSS}>
        {intl.formatMessage(
          {
            defaultMessage: `You're posting on behalf of <strong>{entityName}</strong>`,
            id: "mL4Xcv",
          },
          {
            entityName: government.name,
            // eslint-disable-next-line react/display-name
            strong: (text: string) => <strong css={{ fontWeight: 600 }}>{text}</strong>,
          },
        )}
      </p>
    );
  }

  if (initialValues && initialValues.government) {
    return (
      <p css={paragraphCSS}>
        {intl.formatMessage(
          {
            defaultMessage: `You're posting on behalf of <strong>{entityName}</strong>`,
            id: "mL4Xcv",
          },
          {
            entityName: initialValues.government.name,
            // eslint-disable-next-line react/display-name
            strong: (text: string) => <strong css={{ fontWeight: 600 }}>{text}</strong>,
          },
        )}
      </p>
    );
  }

  if (group) {
    return (
      <p css={paragraphCSS}>
        {intl.formatMessage(
          {
            defaultMessage: "This is a private post and will only be visible to members of {groupName}",
            id: "gMZeZf",
          },
          {
            entityName: group.name,
            // eslint-disable-next-line react/display-name
            strong: (text: string) => <strong css={{ fontWeight: 600 }}>{text}</strong>,
          },
        )}
      </p>
    );
  }

  if (initialValues && initialValues.group) {
    return (
      <p css={paragraphCSS}>
        {intl.formatMessage(
          {
            defaultMessage: "This is a private post and will only be visible to members of {groupName}",
            id: "gMZeZf",
          },
          {
            entityName: initialValues.group.name,
            // eslint-disable-next-line react/display-name
            strong: (text: string) => <strong css={{ fontWeight: 600 }}>{text}</strong>,
          },
        )}
      </p>
    );
  }

  return null;
};
