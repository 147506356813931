import omit from "lodash/fp/omit";
import { IPostInput } from "~/types/types";
import * as MimeTypes from "~/components/post/MimeTypes";

export function convertPostToInitialValues(post: any) {
  const documents = post.attachments.filter(
    (attachment: any) =>
      MimeTypes.isPdf(attachment.mimeType) ||
      MimeTypes.isWord(attachment.mimeType) ||
      MimeTypes.isSheet(attachment.mimeType),
  );

  const images = post.attachments.filter((attachment: any) => MimeTypes.isImage(attachment.mimeType));

  return {
    _id: post._id,
    body: post.body,
    documents,
    images,
    links: post.links,
    type: documents.length > 0 ? "document" : images.length > 0 ? "image" : post.links.length > 0 ? "link" : null,
    audience: "community",
    government: post.government,
    group: post.group,
    vendor: post.vendor,
  };
}

export function convertValuesToVariables(values: any): IPostInput {
  return {
    body: values.body,
    attachments: values.documents.concat(values.images).map(omit(["__typename"])),
    links: values.links.map(omit(["__typename"])),
    audience: "community",
  };
}
