import React, { useState, useRef } from "react";
import { Field } from "react-final-form";
import { Input, LoadingIndicator } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import { IMetadataLink } from "~/types/types";
import useUnfurl, { IUnfurl } from "~/lib/hooks/useUnfurl";
import LinkCard from "~/components/post/LinkCard";
import { useIntl, FormattedMessage } from "react-intl";

interface ILinkFieldProps {
  disabled?: boolean;
  onCancel: () => void;
}

export default function LinkField({ disabled = false, onCancel }: ILinkFieldProps) {
  const intl = useIntl();

  return (
    <Field name="links">
      {({ input }) => (
        <div>
          {input.value.length === 0 && (
            <div>
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <NewsworthyInput
                  disabled={disabled}
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Paste a link and press Enter', id: 'YdtjF2',
                  })}
                  onChange={item => {
                    input.onChange([item].concat(input.value));
                    onCancel();
                  }}
                />

                <span
                  role="button"
                  onClick={onCancel}
                  css={{
                    marginLeft: 16,
                    color: palette.darkGray,
                    fontWeight: 600,
                    fontSize: 12,
                    textAlign: "center",
                    textTransform: "uppercase",
                    cursor: "pointer",
                  }}
                >
                  <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
                </span>
              </div>
            </div>
          )}

          {input.value && Array.isArray(input.value) && input.value.length > 0 && (
            <div
              css={{
                marginTop: 15,
              }}
            >
              {input.value.map(({ url, ...meta }: any) => {
                if (!meta) {
                  return null;
                }

                return (
                  <div key={url || ""}>
                    <LinkCard
                      url={url || ""}
                      title={meta.title || ""}
                      siteName={meta.siteName || ""}
                      description={meta.description || ""}
                      favicon={meta.favicon || ""}
                      onRemove={() => {
                        input.onChange(
                          input.value.filter(({ url: maybe }: IMetadataLink) => {
                            return url !== maybe;
                          }),
                        );
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </Field>
  );
}

interface INewsworthyInputProps {
  onChange: (value: IUnfurl) => any;
  disabled?: boolean;
  placeholder: string;
}

const NewsworthyInput: React.FunctionComponent<INewsworthyInputProps> = ({
  disabled = false,
  placeholder,
  onChange,
}) => {
  const [url, setUrl] = useState("");
  const inputRef = useRef<HTMLInputElement>();

  const [loading] = useUnfurl(
    url,
    unfurl => {
      (inputRef.current as HTMLInputElement).value = "";
      onChange(unfurl);
    },
    () => {
      (inputRef.current as HTMLInputElement).value = "";
    },
  );

  return (
    <div
      css={{
        position: "relative",
        width: "100%",
      }}
    >
      <Input
        innerRef={inputRef}
        disabled={loading || disabled}
        autoFocus={true}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();
            setUrl((event.target as HTMLInputElement).value);
          }
        }}
        placeholder={placeholder}
        size="small"
        css={{
          paddingRight: loading ? 40 : 20,
        }}
      />

      {loading && (
        <div
          css={{
            position: "absolute",
            top: "50%",
            right: 20,
            transform: "translateY(-50%)",
          }}
        >
          <LoadingIndicator />
        </div>
      )}
    </div>
  );
};
