import React, { SVGProps } from "react";

const ColoredThumbsUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 19" width={18} height={19} {...props}>
    <g transform="translate(.706 .36)" fillRule="nonzero" fill="none">
      <path stroke="#0071BB" fill="#FFF" d="M.176 6.66h5.295v9H.176z" />
      <path
        d="M15.176 11.34h-.352a.178.178 0 00-.177.18c0 .1.08.18.177.18a1.25 1.25 0 011.235 1.26 1.25 1.25 0 01-1.235 1.26h-.353a.178.178 0 00-.177.18c0 .1.08.18.177.18a1.25 1.25 0 011.235 1.26 1.25 1.25 0 01-1.235 1.26h-1.068c-.011 0-1.188.06-2.218.06-.598 0-1.027-.02-1.276-.058-.48-.074-1.113-.338-1.785-.618-.906-.377-1.84-.767-2.653-.818V6.66h1.94c.061 0 .118-.032.15-.084.076-.122 1.867-3.02 2.141-4.38.163-.81.628-2.016 1.24-2.016.372 0 .753.176.994.458.194.227.28.503.242.777-.057.418-.281 1.059-.498 1.678-.478 1.366-.747 2.226-.462 2.639a.491.491 0 00.43.208h3.881a1.25 1.25 0 011.236 1.26 1.25 1.25 0 01-1.236 1.26h-.353a.178.178 0 00-.176.18c0 .1.08.18.176.18a1.25 1.25 0 011.236 1.26 1.25 1.25 0 01-1.236 1.26z"
        stroke="#0071BB"
        fill="#0071BB"
      />
      <circle fill="#0071BB" cx={2.471} cy={12.96} r={1} />
    </g>
  </svg>
);

export default ColoredThumbsUpIcon;
