import { gql } from "@apollo/client";
import GroupFields from "~/lib/queries/fragments/GroupFields";
import FollowingFields from "~/lib/queries/fragments/FollowingFields";

export default gql`
  ${GroupFields}
  ${FollowingFields}

  query GetProfile($slug: String!) {
    user(slug: $slug) {
      ... on PendingUser {
        _id
        slug
        firstName
        lastName
        fullName
        email
        notificationEmail
        department {
          _id
          name
        }
        interests {
          _id
          name
        }
        cover {
          original
          repositioned
        }
        avatar
        jobTitle
        postsCount
        upvotesCount
        projectsCount
        savedStoriesCount
        savedProductsCount
        savedProjectsCount
        savedCount
        savedPostsCount
        storiesCount
        projectsCount
        isFollowing
        linkedinUrl
        isInnovator
        isSelfie
        isSiteAdmin
        searchable
        preferences {
          showAsAuthorOnProjects
        }
        newsletterFrequency
        subscribedToNewsletter
        subscribedToCampaign
        socialMedia {
          youtube
          twitter
          instagram
          linkedin
          facebook
        }
        following {
          ...FollowingFields
        }
        primaryGroup {
          _id
        }
        groups {
          _id
          ...GroupFields
        }
        country
        language
        timeZone
      }
      ... on GovernmentUser {
        _id
        slug
        firstName
        lastName
        fullName
        email
        notificationEmail
        department {
          _id
          name
        }
        interests {
          _id
          name
        }
        enabledSharing
        avatar
        jobTitle
        upvotesCount
        storiesCount
        projectsCount
        postsCount
        savedStoriesCount
        savedProductsCount
        savedProjectsCount
        savedPostsCount
        savedCount
        isFollowing
        isInnovator
        isSelfie
        isSiteAdmin
        searchable
        preferences {
          showAsAuthorOnProjects
        }
        newsletterFrequency
        subscribedToNewsletter
        subscribedToCampaign
        socialMedia {
          youtube
          twitter
          instagram
          linkedin
          facebook
        }
        linkedinUrl
        sharing {
          projects
          stories
          resources
          posts
        }
        resources {
          ... on LinkResource {
            type
            title
            url
            addedAt
          }
          ... on DownloadResource {
            type
            filename
            title
            url
            addedAt
          }
          ... on TextResource {
            type
            title
            body
            addedAt
          }
        }
        government {
          _id
          slug
          logo
          name
          viewerIsMember
          members {
            _id
            slug
            avatar
            firstName
            fullName
            jobTitle
          }
          city {
            _id
            name
            country
            countryName
          }
        }
        cover {
          original
          repositioned
        }
        following {
          ...FollowingFields
        }
        followers {
          ... on PendingUser {
            _id
            slug
            avatar
            firstName
            fullName
            jobTitle
            isFollowing
          }
          ... on VendorUser {
            _id
            slug
            avatar
            firstName
            fullName
            jobTitle
            isFollowing
            isDisruptor
          }
          ... on GovernmentUser {
            _id
            slug
            avatar
            firstName
            fullName
            jobTitle
            isFollowing
            isInnovator
          }
        }
        posts {
          ... on GroupPost {
            _id
            body
            audience
            viewerCanEdit
            upvotesCount
            commentsCount
            viewerDidBookmark
            viewerDidUpvote
            viewerDidComment
            createdAt
            author {
              ... on GovernmentUser {
                _id
                slug
                firstName
                fullName
                avatar
                jobTitle
                email
                government {
                  _id
                  slug
                  name
                  logo
                }
              }
              ... on VendorUser {
                _id
                slug
                firstName
                fullName
                avatar
                jobTitle
                email
                company {
                  _id
                  slug
                  name
                  logo
                }
              }
            }
            group {
              _id
              slug
              name
            }
            links {
              url
              description
              favicon
              title
              siteName
              addedAt
            }
            attachments {
              mimeType
              url
              filename
              sizeInBytes
            }
          }
          ... on GovernmentPost {
            _id
            body
            audience
            viewerCanEdit
            upvotesCount
            commentsCount
            viewerDidBookmark
            viewerDidUpvote
            viewerDidComment
            createdAt
            government {
              _id
              slug
              name
              logo
              city {
                _id
                country
                countryName
              }
            }
            author {
              __typename
              ... on GovernmentUser {
                _id
                slug
                firstName
                fullName
                avatar
                jobTitle
                email
                government {
                  _id
                  slug
                  name
                  logo
                }
              }
              ... on VendorUser {
                _id
                slug
                firstName
                fullName
                avatar
                jobTitle
                email
                company {
                  _id
                  slug
                  name
                }
              }
            }
            links {
              url
              description
              favicon
              title
              siteName
              addedAt
            }
            attachments {
              mimeType
              url
              filename
              sizeInBytes
            }
          }

          ... on VendorPost {
            _id
            body
            audience
            viewerCanEdit
            upvotesCount
            commentsCount
            viewerDidBookmark
            viewerDidUpvote
            viewerDidComment
            createdAt
            vendor {
              _id
              slug
              name
              logo
            }
            author {
              __typename
              ... on GovernmentUser {
                _id
                slug
                fullName
                avatar
                jobTitle
                email
                government {
                  _id
                  slug
                  name
                  logo
                }
              }
              ... on VendorUser {
                _id
                slug
                fullName
                avatar
                jobTitle
                email
                company {
                  _id
                  slug
                  name
                  logo
                }
              }
            }
            links {
              url
              description
              favicon
              title
              siteName
              addedAt
            }
            attachments {
              mimeType
              url
              filename
              sizeInBytes
            }
          }
        }
        primaryGroup {
          _id
        }
        groups {
          _id
          ...GroupFields
        }
        country
        language
        timeZone
      }
      ... on VendorUser {
        _id
        slug
        firstName
        lastName
        email
        notificationEmail
        department {
          _id
          name
        }
        interests {
          _id
          name
        }
        fullName
        postsCount
        upvotesCount
        storiesCount
        projectsCount
        savedStoriesCount
        savedProductsCount
        savedProjectsCount
        savedPostsCount
        savedCount
        avatar
        jobTitle
        isFollowing
        isDisruptor
        showDeleteUserWarning
        isSelfie
        isSiteAdmin
        searchable
        preferences {
          showAsAuthorOnProjects
        }
        newsletterFrequency
        subscribedToNewsletter
        subscribedToCampaign
        socialMedia {
          youtube
          twitter
          instagram
          linkedin
          facebook
        }
        linkedinUrl
        company {
          _id
          name
          slug
          logo
          members {
            _id
            slug
            avatar
            firstName
            fullName
            jobTitle
          }
        }
        cover {
          original
          repositioned
        }
        following {
          ...FollowingFields
        }
        primaryGroup {
          _id
        }
        groups {
          _id
          ...GroupFields
        }
        country
        language
        timeZone
      }
    }
  }
`;
