import React, { SVGProps, ReactElement } from "react";
import RemoveButton from "~/components/post/RemoveButton";
import * as palette from "@govlaunch/palette";
import { isPdf, isSheet, isWord } from "~/components/post/MimeTypes";

interface IDocumentCardProps {
  url: string;
  filename: string;
  mimeType: string;
  sizeInBytes: number;
  onRemove?: ((url: any) => any) | null;
}

interface IDocumentCardColor {
  background: string;
  borderColor: string;
}

function getCardColor(mimeType: string): IDocumentCardColor {
  if (isPdf(mimeType)) {
    return {
      background: "#ffdfdf",
      borderColor: "#f6bdbd",
    };
  } else if (isSheet(mimeType)) {
    return {
      background: "#bde4c7",
      borderColor: "#91cda0",
    };
  } else {
    return {
      background: "#e3f3fb",
      borderColor: "#c1dae6",
    };
  }
}

export default function DocumentCard({
  url,
  filename,
  mimeType,
  sizeInBytes,
  onRemove = null,
}: IDocumentCardProps): ReactElement {
  const color = getCardColor(mimeType);

  return (
    <div
      css={{
        position: "relative",
        background: color.background,
        borderRadius: 4,
        border: `1px solid ${color.borderColor}`,
      }}
    >
      <a
        href={url}
        target="_blank"
        rel="noreferrer noopener"
        css={{
          padding: 8,
          display: "block",
        }}
      >
        <div
          css={{
            display: "flex",
          }}
        >
          {isWord(mimeType) && (
            <span
              css={{
                boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.39)",
              }}
            >
              <Doc width={34} height={44} />
            </span>
          )}
          {isSheet(mimeType) && (
            <span
              css={{
                boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.39)",
              }}
            >
              <Xls width={34} height={44} />
            </span>
          )}
          {isPdf(mimeType) && (
            <span>
              <Pdf width={34} height={44} />
            </span>
          )}

          <div css={{ marginLeft: 12, display: "flex", alignItems: "center" }}>
            <p
              css={{
                margin: 0,
                fontSize: 15,
                fontWeight: "bold",
                color: palette.darkGray,
                wordBreak: "break-word",
              }}
            >
              {filename}
            </p>
            <span
              css={{
                color: palette.darkGray,
                padding: "0 4px",
              }}
            >
              {" "}
              •{" "}
            </span>
            <p
              css={{
                margin: 0,
                fontSize: 12,
                color: palette.darkGray,
                wordBreak: "break-word",
              }}
            >
              {Math.round(sizeInBytes / 1024)}kb
            </p>
          </div>
        </div>
      </a>

      {onRemove && (
        <RemoveButton
          onClick={() => onRemove(url)}
          css={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
        />
      )}
    </div>
  );
}

const Xls = (props: SVGProps<any>) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      d="M36.985 0H7.963C7.155 0 6.5.655 6.5 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L37.607.257A.884.884 0 0036.985 0z"
      fill="#e9e9e0"
    />
    <path fill="#d9d7ca" d="M37.5.151V12h11.849z" />
    <path d="M48.037 56H7.963A1.463 1.463 0 016.5 54.537V39h43v15.537c0 .808-.655 1.463-1.463 1.463z" fill="#91cda0" />
    <g fill="#fff">
      <path d="M20.379 48.105L22.936 53h-1.9l-1.6-3.801h-.137L17.576 53h-1.9l2.557-4.895-2.721-5.182h1.873l1.777 4.102h.137l1.928-4.102H23.1l-2.721 5.182zM27.037 42.924v8.832h4.635V53h-6.303V42.924h1.668zM39.041 50.238c0 .364-.075.718-.226 1.06s-.362.642-.635.902-.611.467-1.012.622a3.771 3.771 0 01-1.367.232c-.219 0-.444-.012-.677-.034s-.467-.062-.704-.116a3.686 3.686 0 01-.677-.226 2.17 2.17 0 01-.554-.349l.287-1.176c.127.073.289.144.485.212s.398.132.608.191c.209.06.419.107.629.144.209.036.405.055.588.055.556 0 .982-.13 1.278-.39.296-.26.444-.645.444-1.155 0-.31-.105-.574-.314-.793-.21-.219-.472-.417-.786-.595s-.654-.355-1.019-.533a6.173 6.173 0 01-1.025-.629 3.281 3.281 0 01-.793-.854c-.21-.328-.314-.738-.314-1.23 0-.446.082-.843.246-1.189s.385-.641.663-.882.602-.426.971-.554.759-.191 1.169-.191c.419 0 .843.039 1.271.116.428.077.774.203 1.039.376a13.31 13.31 0 01-.191.39l-.205.396c-.064.123-.119.226-.164.308a1.263 1.263 0 01-.082.137c-.055-.027-.116-.063-.185-.109s-.167-.091-.294-.137a2.097 2.097 0 00-.506-.096 4.781 4.781 0 00-.807.014c-.183.019-.355.07-.52.157s-.31.193-.438.321a1.464 1.464 0 00-.301.431 1.1 1.1 0 00-.109.458c0 .364.104.658.314.882.209.224.469.419.779.588.31.169.647.333 1.012.492.364.159.704.354 1.019.581s.576.513.786.854c.212.342.317.781.317 1.319z" />
    </g>
    <path
      d="M23.5 16v-4h-12v22h33V16h-21zm-10-2h8v2h-8v-2zm0 4h8v2h-8v-2zm0 4h8v2h-8v-2zm0 4h8v2h-8v-2zm8 6h-8v-2h8v2zm21 0h-19v-2h19v2zm0-4h-19v-2h19v2zm0-4h-19v-2h19v2zm-19-4v-2h19v2h-19z"
      fill="#c8bdb8"
    />
  </svg>
);

const Pdf = (props: SVGProps<any>) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      d="M36.985 0H7.963C7.155 0 6.5.655 6.5 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L37.607.257A.884.884 0 0036.985 0z"
      fill="#e9e9e0"
    />
    <path fill="#d9d7ca" d="M37.5.151V12h11.849z" />
    <path
      d="M19.514 33.324c-.348 0-.682-.113-.967-.326-1.041-.781-1.181-1.65-1.115-2.242.182-1.628 2.195-3.332 5.985-5.068 1.504-3.296 2.935-7.357 3.788-10.75-.998-2.172-1.968-4.99-1.261-6.643.248-.579.557-1.023 1.134-1.215a4.91 4.91 0 011.016-.172c.504 0 .947.649 1.261 1.049.295.376.964 1.173-.373 6.802 1.348 2.784 3.258 5.62 5.088 7.562 1.311-.237 2.439-.358 3.358-.358 1.566 0 2.515.365 2.902 1.117.32.622.189 1.349-.39 2.16-.557.779-1.325 1.191-2.22 1.191-1.216 0-2.632-.768-4.211-2.285-2.837.593-6.15 1.651-8.828 2.822-.836 1.774-1.637 3.203-2.383 4.251-1.025 1.435-1.909 2.105-2.784 2.105zm2.662-5.126c-2.137 1.201-3.008 2.188-3.071 2.744-.01.092-.037.334.431.692.149-.047 1.019-.444 2.64-3.436zm13.637-4.442c.815.627 1.014.944 1.547.944.234 0 .901-.01 1.21-.441.149-.209.207-.343.23-.415-.123-.065-.286-.197-1.175-.197-.505.001-1.14.023-1.812.109zm-7.47-6.582a71.291 71.291 0 01-2.674 7.564 49.966 49.966 0 016.496-2.02c-1.35-1.568-2.699-3.526-3.822-5.544zm-.607-8.462c-.098.033-1.33 1.757.096 3.216.949-2.115-.053-3.23-.096-3.216zM48.037 56H7.963A1.463 1.463 0 016.5 54.537V39h43v15.537c0 .808-.655 1.463-1.463 1.463z"
      fill="#cc4b4c"
    />
    <g fill="#fff">
      <path d="M17.385 53h-1.641V42.924h2.898c.428 0 .852.068 1.271.205.419.137.795.342 1.128.615.333.273.602.604.807.991s.308.822.308 1.306c0 .511-.087.973-.26 1.388a2.9 2.9 0 01-.725 1.046c-.31.282-.684.501-1.121.656s-.921.232-1.449.232h-1.217V53zm0-8.832v3.992h1.504c.2 0 .398-.034.595-.103.196-.068.376-.18.54-.335.164-.155.296-.371.396-.649.1-.278.15-.622.15-1.032 0-.164-.023-.354-.068-.567a1.637 1.637 0 00-.28-.615 1.657 1.657 0 00-.595-.492c-.255-.132-.593-.198-1.012-.198h-1.23zM32.219 47.682c0 .829-.089 1.538-.267 2.126s-.403 1.08-.677 1.477-.581.709-.923.937-.672.398-.991.513a4.094 4.094 0 01-.875.219c-.264.03-.46.046-.588.046h-3.814V42.924h3.035c.848 0 1.593.135 2.235.403s1.176.627 1.6 1.073.74.955.95 1.524c.21.57.315 1.156.315 1.758zm-4.867 4.115c1.112 0 1.914-.355 2.406-1.066s.738-1.741.738-3.09c0-.419-.05-.834-.15-1.244-.101-.41-.294-.781-.581-1.114s-.677-.602-1.169-.807-1.13-.308-1.914-.308h-.957v7.629h1.627zM36.266 44.168v3.172h4.211v1.121h-4.211V53h-1.668V42.924H40.9v1.244h-4.634z" />
    </g>
  </svg>
);

const Doc = (props: SVGProps<any>) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      d="M36.985 0H7.963C7.155 0 6.5.655 6.5 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L37.607.257A.884.884 0 0036.985 0z"
      fill="#e9e9e0"
    />
    <path fill="#d9d7ca" d="M37.5.151V12h11.849z" />
    <path
      d="M18.5 13h-6a1 1 0 010-2h6a1 1 0 010 2zM21.5 18h-9a1 1 0 010-2h9a1 1 0 010 2zM25.5 18c-.26 0-.52-.11-.71-.29-.18-.19-.29-.45-.29-.71 0-.26.11-.52.29-.71.37-.37 1.05-.37 1.42 0 .18.19.29.45.29.71 0 .26-.11.52-.29.71-.19.18-.45.29-.71.29zM37.5 18h-8a1 1 0 010-2h8a1 1 0 010 2zM12.5 33c-.26 0-.52-.11-.71-.29-.18-.19-.29-.45-.29-.71 0-.26.11-.52.29-.71.37-.37 1.05-.37 1.42 0 .18.19.29.44.29.71 0 .26-.11.52-.29.71-.19.18-.45.29-.71.29zM24.5 33h-8a1 1 0 010-2h8a1 1 0 010 2zM43.5 18h-2a1 1 0 010-2h2a1 1 0 010 2zM34.5 23h-22a1 1 0 010-2h22a1 1 0 010 2zM43.5 23h-6a1 1 0 010-2h6a1 1 0 010 2zM16.5 28h-4a1 1 0 010-2h4a1 1 0 010 2zM30.5 28h-10a1 1 0 010-2h10a1 1 0 010 2zM43.5 28h-9a1 1 0 010-2h9a1 1 0 010 2z"
      fill="#8697cb"
    />
    <path d="M48.037 56H7.963A1.463 1.463 0 016.5 54.537V39h43v15.537c0 .808-.655 1.463-1.463 1.463z" fill="#0096e6" />
    <g fill="#fff">
      <path d="M23.5 47.682c0 .829-.089 1.538-.267 2.126s-.403 1.08-.677 1.477-.581.709-.923.937-.672.398-.991.513a4.094 4.094 0 01-.875.219c-.264.03-.46.046-.587.046h-3.814V42.924H18.4c.848 0 1.593.135 2.235.403s1.176.627 1.6 1.073.74.955.95 1.524c.21.57.315 1.156.315 1.758zm-4.867 4.115c1.112 0 1.914-.355 2.406-1.066s.738-1.741.738-3.09c0-.419-.05-.834-.15-1.244-.101-.41-.294-.781-.581-1.114s-.677-.602-1.169-.807-1.13-.308-1.914-.308h-.957v7.629h1.627zM33.475 47.914c0 .848-.107 1.595-.321 2.242-.214.647-.511 1.185-.889 1.613-.378.429-.82.752-1.326.971s-1.06.328-1.661.328-1.155-.109-1.661-.328-.948-.542-1.326-.971c-.378-.429-.675-.966-.889-1.613-.214-.647-.321-1.395-.321-2.242s.107-1.593.321-2.235c.214-.643.51-1.178.889-1.606.378-.429.82-.754 1.326-.978s1.06-.335 1.661-.335 1.155.111 1.661.335.948.549 1.326.978c.378.429.674.964.889 1.606.213.642.321 1.387.321 2.235zm-4.239 3.815c.337 0 .658-.066.964-.198.305-.132.579-.349.82-.649.241-.301.431-.695.567-1.183s.209-1.082.219-1.784c-.009-.684-.08-1.265-.212-1.743-.132-.479-.314-.873-.547-1.183s-.497-.533-.793-.67a2.203 2.203 0 00-.937-.205c-.337 0-.659.063-.964.191a2.065 2.065 0 00-.82.649c-.242.306-.431.699-.567 1.183s-.21 1.075-.219 1.777c.009.684.08 1.267.212 1.75.132.483.314.877.547 1.183s.497.528.793.67c.297.141.609.212.937.212zM42.607 51.975a3.73 3.73 0 01-1.271.82 4.212 4.212 0 01-1.531.273c-.602 0-1.155-.109-1.661-.328s-.948-.542-1.326-.971c-.378-.429-.675-.966-.889-1.613-.214-.647-.321-1.395-.321-2.242s.107-1.593.321-2.235c.214-.643.51-1.178.889-1.606a3.78 3.78 0 011.333-.978 4.069 4.069 0 011.654-.335c.547 0 1.057.091 1.531.273.474.183.897.456 1.271.82l-1.135 1.012a2.072 2.072 0 00-1.627-.752c-.337 0-.659.063-.964.191a2.065 2.065 0 00-.82.649c-.242.306-.431.699-.567 1.183s-.21 1.075-.219 1.777c.009.684.08 1.267.212 1.75.132.483.314.877.547 1.183s.497.528.793.67c.296.142.608.212.937.212s.636-.06.923-.178.549-.31.786-.574l1.134.999z" />
    </g>
  </svg>
);
