import * as palette from "@govlaunch/palette";
import { Box, CountryFlag, TCountryCode, Tooltip } from "@govlaunch/web";
import Link from "next/link";
import React from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import { useIntl } from "react-intl";
import { useToggle } from "react-use";
import {
  ICity,
  ICompany,
  IGovernment,
  IGovernmentUser,
  IGroup,
  IPendingUser,
  IPost,
  IVendorUser,
} from "~/types/types";
import FeedAuthorPicture from "~/components/feed/card/FeedAuthorPicture";
import FeedCardPitch from "~/components/feed/card/FeedCardPitch";
import { FeedContext } from "~/components/feed/interfaces";
import { Margin } from "~/components/spacings";
import DocumentCard from "~/components/post/DocumentCard";
import LinkCard from "~/components/post/LinkCard";
import { isImage } from "~/components/post/MimeTypes";

type Government = Pick<IGovernment, "_id" | "slug" | "name" | "logo"> & {
  city: Pick<ICity, "country" | "countryName">;
};
type Company = Pick<ICompany, "_id" | "slug" | "name" | "logo">;
type Group = Pick<IGroup, "_id" | "name" | "slug" | "logo">;

type Author =
  | ({ __typename: "GovernmentUser" } & Pick<
      IGovernmentUser,
      "_id" | "slug" | "fullName" | "avatar" | "jobTitle" | "email"
    > & {
        government: Government | null;
      })
  | ({ __typename: "VendorUser" } & Pick<IVendorUser, "_id" | "slug" | "fullName" | "avatar" | "jobTitle" | "email"> & {
        company: Company | null;
      })
  | ({ __typename: "PendingUser" } & Pick<IPendingUser, "_id" | "slug" | "fullName" | "avatar" | "jobTitle" | "email">);

interface IPostDetailsProps {
  post: Pick<IPost, "_id" | "body" | "viewerCanEdit" | "links" | "attachments" | "createdAt"> & {
    author: Author;
  } & {
    government: Government | null;
  } & {
    group: Group | null;
  };
  context?: FeedContext;
}

interface IEntityLink {
  author: Author;
  government: Government | null;
  group: Group | null;
  context?: FeedContext;
}

const EntityLink: React.FunctionComponent<IEntityLink> = ({ author, government, group, context }) => {
  if (context === "group") {
    if (author.__typename === "GovernmentUser" && author.government) {
      return (
        <Link
          href="/governments/[governmentSlug]/[[...content]]"
          as={`/governments/${author.government.slug}`}
          passHref={true}
        >
          <a
            css={{
              fontSize: 14,
              color: palette.darkGray,
              fontWeight: "bold",
              wordBreak: "break-word",
              ":hover": {
                textDecoration: "underline !important",
              },
            }}
          >
            {author.government.name}
          </a>
        </Link>
      );
    }

    if (author.__typename === "VendorUser" && author.company) {
      return (
        <Link href="/vendors/[vendorSlug]" as={`/vendors/${author.company.slug}`} passHref={true}>
          <a
            css={{
              fontSize: 14,
              color: palette.darkGray,
              fontWeight: "bold",
              wordBreak: "break-word",
              ":hover": {
                textDecoration: "underline !important",
              },
            }}
          >
            {author.company.name}
          </a>
        </Link>
      );
    }

    return null;
  }

  if (government) {
    return (
      <Link href="/governments/[governmentSlug]/[[...content]]" as={`/governments/${government.slug}`} passHref={true}>
        <a
          css={{
            fontSize: 14,
            color: palette.darkGray,
            fontWeight: "bold",
            wordBreak: "break-word",
            display: "flex",
            alignItems: "center",
            ":hover": {
              textDecoration: "underline !important",
            },
          }}
        >
          {government.name}

          <Tooltip
            maxWidth="230px"
            textAlign="center"
            aria-label={government.name}
            label={government.city.countryName}
            bg="blue.600"
            py={2}
          >
            <Box as="span" display="inline-flex" ml={2}>
              <CountryFlag countryCode={government.city.country as TCountryCode} width={25} height={14} />
            </Box>
          </Tooltip>
        </a>
      </Link>
    );
  }

  if (group) {
    return (
      <Link passHref={true} href="/groups/[groupSlug]" as={`/groups/${group.slug}`}>
        <a
          css={{
            fontSize: 14,
            color: palette.darkGray,
            fontWeight: "bold",
            wordBreak: "break-word",
            ":hover": {
              textDecoration: "underline !important",
            },
          }}
        >
          {group.name}
        </a>
      </Link>
    );
  }

  return null;
};

export default function PostDetails({ post, context }: IPostDetailsProps) {
  const [isLightboxOpen, toggleLightbox] = useToggle(false);
  const intl = useIntl();
  const [image] = post.attachments.filter(({ mimeType }) => isImage(mimeType));
  const { author, government, group } = post;

  return (
    <div>
      <div
        css={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <FeedAuthorPicture author={author} context={context} government={government} group={group} />

        <Margin ml={10}>
          <FeedCardPitch
            author={author}
            government={government}
            action={intl.formatMessage({ id: 'uxICV0', defaultMessage: "shared a" })}
            target={intl.formatMessage({ defaultMessage: 'post', id: 'X6hX1L' })}
            targetLink={{
              href: `/post?postId=${post._id}`,
              as: `/posts/${post._id}`,
            }}
            date={post.createdAt}
          />

          <Margin mt={4}>
            <EntityLink author={author} government={government} group={group} context={context} />
          </Margin>
        </Margin>
      </div>

      <Margin mt={12}>
        <div
          css={{
            display: "grid",
            gridRowGap: 12,
          }}
        >
          <p
            css={{
              justifySelf: "flex-start",
              fontSize: 15,
              color: palette.darkGray,
              wordBreak: "break-word",
            }}
          >
            {post.body}
          </p>

          {post.links.map((link, index) => (
            <LinkCard
              url={link.url || ""}
              description={link.description || ""}
              favicon={link.favicon}
              title={link.title || ""}
              siteName={link.siteName || ""}
              key={link.url || index}
            />
          ))}

          {image && (
            <>
              <div css={{ display: "flex" }}>
                <img
                  src={image.url}
                  onClick={toggleLightbox}
                  css={{
                    cursor: "pointer",
                    maxHeight: 250,
                    maxWidth: "100%",
                    objectFit: "contain",
                    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.39)",
                  }}
                />
              </div>

              <ModalGateway>
                {isLightboxOpen ? (
                  <Modal onClose={toggleLightbox} closeOnBackdropClick={true}>
                    <Carousel views={[image].map((image) => ({ source: image.url }))} currentIndex={0} />
                  </Modal>
                ) : null}
              </ModalGateway>
            </>
          )}

          {post.attachments
            .filter(({ mimeType }) => !isImage(mimeType))
            .map((document) => {
              return (
                <DocumentCard
                  filename={document.filename}
                  mimeType={document.mimeType}
                  url={document.url}
                  key={document.url}
                  sizeInBytes={document.sizeInBytes}
                />
              );
            })}
        </div>
      </Margin>
    </div>
  );
}
