import { gql } from "@apollo/client";
import GovernmentPostFields from "~/lib/queries/fragments/GovernmentPostFields";
import GroupPostFields from "~/lib/queries/fragments/GroupPostFields";

export default gql`
  mutation PostToGovernment($government: ObjectId!, $post: PostInput!) {
    postToGovernment(government: $government, post: $post) {
      ... on GovernmentPost {
        _id
        ...GovernmentPostFields
      }

      ... on GroupPost {
        _id
        ...GroupPostFields
      }
    }
  }

  ${GovernmentPostFields}
  ${GroupPostFields}
`;
