import * as palette from "@govlaunch/palette";
import Link, { LinkProps } from "next/link";
import React from "react";
import IntlRelativeTime from "~/src/shared/components/IntlRelativeTime";
import * as Types from "~/types/types";
import mediaQueries from "~/components/mediaQueries";
import { Margin } from "~/components/spacings";
import { Author } from "~/components/feed/interfaces";

interface IFeedCardPitch {
  author?: Author | null;
  government?: Pick<Types.IGovernment, "_id" | "slug" | "name"> | null;
  vendor?: Pick<Types.ICompany, "_id" | "slug" | "name"> | null;
  action: string;
  target: string;
  date: Types.Maybe<Types.Scalars["DateTime"]>;
  targetLink: LinkProps;
  gatedAuthor?: boolean;
}

interface IEntityName {
  author?: Author | null;
  government?: Pick<Types.IGovernment, "_id" | "slug" | "name"> | null;
  vendor?: Pick<Types.ICompany, "_id" | "slug" | "name"> | null;
  gatedAuthor?: boolean;
}

interface ITarget {
  target: string;
  targetLink: LinkProps;
}

interface ITime {
  date: Types.Maybe<Types.Scalars["DateTime"]>;
  targetLink: LinkProps;
}

const FeedCardPitch: React.FunctionComponent<IFeedCardPitch> = ({
  author,
  gatedAuthor = false,
  government,
  vendor,
  action,
  target,
  targetLink,
  date,
}) => {
  return (
    <div
      css={mediaQueries({
        color: palette.darkGray,
        display: "flex",
        flexDirection: ["column", "row"],
        fontSize: 15,
      })}
    >
      <EntityName author={author} government={government} vendor={vendor} gatedAuthor={gatedAuthor} />
      <Margin mx={[0, 5]}>
        {action} <Target target={target} targetLink={targetLink} />
      </Margin>
      <Time date={date} targetLink={targetLink} />
    </div>
  );
};

export const Time: React.FunctionComponent<ITime> = ({ date, targetLink }) => {
  return (
    <span
      css={{
        display: "flex",
        alignItems: "center",
        fontSize: 13,
        color: "#adadad",
        letterSpacing: 0.5,
      }}
    >
      •
      <Margin ml={3}>
        <Link {...targetLink} passHref={true}>
          <a
            css={{
              color: "#adadad",
              ":hover": {
                textDecoration: "underline !important",
              },
            }}
          >
            <IntlRelativeTime value={date} />
          </a>
        </Link>
      </Margin>
    </span>
  );
};

const Target: React.FunctionComponent<ITarget> = ({ target, targetLink }) => {
  return (
    <Link {...targetLink} passHref={true}>
      <a
        css={{
          color: palette.darkGray,
          fontWeight: 600,
          ":hover": {
            textDecoration: "underline !important",
          },
        }}
      >
        {target}
      </a>
    </Link>
  );
};

const EntityName: React.FunctionComponent<IEntityName> = ({ gatedAuthor, author, government, vendor }) => {
  if (gatedAuthor && government) {
    return (
      <Link
        href={`/government?governmentSlug=${government.slug}`}
        as={`/government/${government.slug}`}
        passHref={true}
      >
        <a
          css={{
            color: palette.primary,
            fontWeight: "bold",
            ":hover": {
              textDecoration: "underline !important",
            },
          }}
        >
          {government.name}
        </a>
      </Link>
    );
  }

  if (gatedAuthor && vendor) {
    return (
      <Link href={`/vendor?vendorSlug=${vendor.slug}`} as={`/vendor/${vendor.slug}`} passHref={true}>
        <a
          css={{
            color: palette.primary,
            fontWeight: "bold",
            ":hover": {
              textDecoration: "underline !important",
            },
          }}
        >
          {vendor.name}
        </a>
      </Link>
    );
  }

  if (author && author.slug) {
    return (
      <Link href={`/user?userSlug=${author.slug}`} as={`/user/${author.slug}`} passHref={true}>
        <a
          css={{
            color: palette.primary,
            fontWeight: "bold",
            ":hover": {
              textDecoration: "underline !important",
            },
          }}
        >
          {author.fullName}
        </a>
      </Link>
    );
  }

  return null;
};

export default FeedCardPitch;
