import { gql } from "@apollo/client";
import GovernmentPostFields from "~/lib/queries/fragments/GovernmentPostFields";
import VendorPostFields from "~/lib/queries/fragments/VendorPostFields";
import GroupPostFields from "~/lib/queries/fragments/GroupPostFields";

export default gql`
  mutation EditPost($id: ObjectId!, $post: PostInput!) {
    editPost(id: $id, post: $post) {
      ... on GovernmentPost {
        _id
        ...GovernmentPostFields
      }
      ... on GroupPost {
        _id
        ...GroupPostFields
      }
      ... on VendorPost {
        _id
        ...VendorPostFields
      }
    }
  }

  ${VendorPostFields}
  ${GovernmentPostFields}
  ${GroupPostFields}
`;
