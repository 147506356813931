import { gql } from "@apollo/client";

export default gql`
  fragment GroupFields on Group {
    _id
    slug
    logo
    name
  }
`;
