import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import { Box, CountryFlag, TCountryCode, Tooltip } from "@govlaunch/web";
import Link from "next/link";
import React from "react";
import * as Types from "~/types/types";
import AnonymousUser from "~/components/icons/AnonymousUser";
import { Author, FeedContext } from "~/components/feed/interfaces";

type Government = Pick<Types.IGovernment, "_id" | "slug" | "name" | "logo"> & {
  city: Pick<Types.ICity, "countryName" | "country">;
};
type Company = Pick<Types.ICompany, "_id" | "slug" | "name" | "logo">;
type Group = Pick<Types.IGroup, "_id" | "slug" | "name" | "logo">;

interface IFeedAuthorPicture {
  author?: Author | null;
  government?: Government | null;
  vendor?: Company | null;
  group?: Group | null;
  gatedAuthor?: boolean;
  context?: FeedContext | null;
}

interface IEntityLogo {
  author: Author;
  context?: FeedContext | null;
  government?: Government | null;
  group?: Group | null;
}

interface IGovernmentLogo {
  government: Government;
}

interface ICompanyLogo {
  company: Company;
}

interface IGroupLogo {
  group: Group;
}

const FeedAuthorPicture: React.FunctionComponent<IFeedAuthorPicture> = ({
  gatedAuthor = false,
  author,
  government,
  vendor,
  group,
  context,
}) => {
  if (gatedAuthor && government) {
    return (
      <Link href="/governments/[governmentSlug]/[[...content]]" as={`/governments/${government.slug}`} passHref={true}>
        <a
          css={{
            display: "flex",
          }}
        >
          <Tooltip
            maxWidth="230px"
            textAlign="center"
            aria-label={government.name}
            label={
              <span>
                {government.name} <br /> {government.city.countryName}
              </span>
            }
            bg="blue.600"
            py={2}
          >
            <Box position="relative">
              <ProfilePicture size={45} image={government.logo} name={government.name} />
              <Box position="absolute" bottom={1} right={-3}>
                <CountryFlag countryCode={government.city.country as TCountryCode} width={24} height={14} />
              </Box>
            </Box>
          </Tooltip>
        </a>
      </Link>
    );
  }

  if (gatedAuthor && vendor) {
    return (
      <Link href="/vendors/[vendorSlug]" as={`/vendors/${vendor.slug}`} passHref={true}>
        <a
          css={{
            display: "flex",
          }}
        >
          <Tooltip
            maxWidth="230px"
            textAlign="center"
            aria-label={vendor.name}
            label={<span>{vendor.name}</span>}
            bg="blue.600"
            py={2}
          >
            <Box position="relative">
              <ProfilePicture size={45} image={vendor.logo} name={vendor.name} />
            </Box>
          </Tooltip>
        </a>
      </Link>
    );
  }

  if (author && author.slug) {
    return (
      <div
        css={{
          position: "relative",
        }}
      >
        <Link passHref={true} href="/[userSlug]/[[...tab]]" as={`/@${author.slug}`}>
          <a
            css={{
              display: "flex",
            }}
          >
            <ProfilePicture size={45} image={author.avatar} name={author.fullName} />
          </a>
        </Link>

        <EntityLogo author={author} context={context} government={government} group={group} />
      </div>
    );
  }

  if (author) {
    return <AnonymousUser height={45} width={45} />;
  }

  return null;
};

const EntityLogo: React.FunctionComponent<IEntityLogo> = ({ author, context, government, group }) => {
  if (context === "group") {
    if (author.__typename === "GovernmentUser" && author.government) {
      return <GovernmentLogo government={author.government} />;
    }

    if (author.__typename === "VendorUser" && author.company) {
      return <CompanyLogo company={author.company} />;
    }
  }

  if (government) {
    return <GovernmentLogo government={government} />;
  }

  if (group) {
    return <GroupLogo group={group} />;
  }

  if (author.__typename === "GovernmentUser" && author.government) {
    return <GovernmentLogo government={author.government} />;
  }

  if (author.__typename === "VendorUser" && author.company) {
    return <CompanyLogo company={author.company} />;
  }

  return null;
};

const GovernmentLogo: React.FunctionComponent<IGovernmentLogo> = ({ government }) => {
  return (
    <Link href="/governments/[governmentSlug]/[[...content]]" as={`/governments/${government.slug}`} passHref={true}>
      <a
        css={{
          position: "absolute",
          bottom: -8,
          right: -4,
        }}
      >
        <ProfilePicture
          size={24}
          image={government.logo}
          name={government.name}
          css={{
            borderColor: palette.white,
            borderWidth: 2,
          }}
        />
      </a>
    </Link>
  );
};

const CompanyLogo: React.FunctionComponent<ICompanyLogo> = ({ company }) => {
  return (
    <Link href="/vendors/[vendorSlug]" as={`/vendors/${company.slug}`} passHref={true}>
      <a
        css={{
          position: "absolute",
          bottom: -8,
          right: -4,
        }}
      >
        <ProfilePicture
          size={24}
          image={company.logo}
          name={company.name}
          css={{
            borderColor: palette.white,
            borderWidth: 2,
          }}
        />
      </a>
    </Link>
  );
};

const GroupLogo: React.FunctionComponent<IGroupLogo> = ({ group }) => {
  return (
    <Link passHref={true} href="/groups/[groupSlug]" as={`/groups/${group.slug}`}>
      <a
        css={{
          position: "absolute",
          bottom: -8,
          right: -4,
        }}
      >
        <ProfilePicture
          size={24}
          image={group.logo}
          name={group.name}
          css={{
            borderColor: palette.white,
            borderWidth: 2,
          }}
        />
      </a>
    </Link>
  );
};

export default FeedAuthorPicture;
